import React from 'react'

// JS dependencies
import { Box } from '@mui/material'

// internal React Components
import Text from '../../components/Text'

const Strategies = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
    <Text>
      <span>
        Here display:
        <ul>
          <li>Talk time statistics</li>
          <li>Speaking over others</li>
          <li>Feedback on how other participants felt about the discussion</li>
          <li>What was said - what fraction of shared info was discussed vs hidden info</li>
          <li>self reports - compare what you heard with what others said</li>
        </ul>
      </span>
    </Text>
  </Box>
)
export default Strategies
