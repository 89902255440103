import React from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'

const Text = ({ translation, children, ...rest }) => {
  const t = useIntl()
  const generateRandomString = () => Math.random().toString(36).slice(2)

  const intlSharedValues = {
    br: <br />,
  }
  return (
    <Typography
      key={generateRandomString()}
      component={translation ? 'p' : 'div'}
      gutterBottom
      {...rest}
    >
      {
        translation
          ? t.formatMessage({ id: translation }, intlSharedValues)
          : children
      }
    </Typography>
  )
}
Text.propTypes = {
  variant: PropTypes.string,
  translation: PropTypes.string,
  children: PropTypes.node,
}
Text.defaultProps = {
  variant: 'body1',
  translation: null,
  children: '',
}
export default Text
