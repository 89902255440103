/* eslint max-len: "off" */

import { Buffer } from 'buffer'
import decode from 'safe-decode-uri-component'
import client from './middlewareClient'
import * as session from '../helpers/session'

// POST request to Express middleware, which will handle external calls
// ./server/middleware.js
const request = data => client.post(
  // this is a one-size-fits-all endpoint in the middleware to handle all Github external requests
  '/middleware/stimuli',
  // ex: {
  //   fileName: 'filename_in_repo.ext',
  //   ref: 'session_stimulus_tag',
  // }
  { data },
)

// https://github.com/octokit/plugin-rest-endpoint-methods.js/blob/master/docs/repos/getContent.md
const getRepoContent = (fileName, onSuccess, onError, onFinally) => (
  request({ ref: session.getRound().stimulus_tag, fileName })
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally)
)

// methods exposed to the React Components
export const getDecisionRecollectionContent = (...args) => getRepoContent('decision_recollection.md', ...args)
export const getDiscussionRecollectionContent = (...args) => getRepoContent('discussion_recollection.md', ...args)
export const getFeedbackPrompt = (...args) => getRepoContent('feedbackQuestion.md', ...args)
export const getInitialDiscussionPromptContent = (...args) => getRepoContent('initial_discussion_prompt.md', ...args)
export const getInterventionContent = (...args) => getRepoContent('intervention.md', ...args)
export const getIntroductionInstructionsContent = (...args) => getRepoContent('introduction_instructions.md', ...args)
export const getLongTextQuestionsContent = (...args) => getRepoContent('long_text_questions.csv', ...args)
export const getRecollectionPromptContent = (...args) => getRepoContent('profile_recollection_prompt.md', ...args)
export const getSelectionContent = (...args) => getRepoContent('selection.md', ...args)
export const getTaggedRoundChecklistContent = (...args) => getRepoContent('checklist.md', ...args)
export const getTaggedRoundStimulusContent = (...args) => getRepoContent(`${session.getRound().stimulus_location}.md`, ...args)
export const getTermsAndConditionsContent = (...args) => getRepoContent('terms_and_conditions.md', ...args)

// parse encoded file content from b64 into unicode
export const parseRepoContent = content => (
  decode(
    Buffer.from(content, 'base64')
      .toString('utf8')
      .split('')
      .map(c => (
        `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`
      ))
      .join(''),
  )
)
