import React, { useEffect } from 'react'

// JS dependencies
import confetti from 'canvas-confetti'

// internal helpers
import { prefersReducedMotion } from '../../helpers/accessibility'
import * as kfApi from '../../requests/kfApi'
import * as session from '../../helpers/session'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const logEndRound = () => {
  // in a multi-player setting, only one player needs to update the round. default to first player
  if (session.getRound().player_ids[0] === session.getLocalPlayerId()) {
    const now = new Date()
    kfApi.endRound(session.getRound().id, now.toISOString())
  }
}

const Goodbye = () => {
  useEffect(async () => {
    logEndRound()

    if (!prefersReducedMotion()) {
      // confetti cannon for the players
      // https://www.kirilv.com/canvas-confetti/
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.6 },
      })
    }

    // Wait 5 seconds before wiping all session data
    await setTimeout(5000)
    session.end()
  }, [])

  // TODO: force close the window

  return (
    <Centered fillY>
      <Text translation="goodbye.congrats" variant="h1" />
      <Text translation="goodbye.thanks" variant="h4" />
      <Text translation="goodbye.closeWindow" variant="subtitle1" />
    </Centered>
  )
}
export default Goodbye
