import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import { Box, Divider } from '@mui/material'

// internal React Components
import Stimulus from '../../components/Stimulus'
import Text from '../../components/Text'

const ProfileRecollection = ({ beforeContinue, setFormInputs }) => {
  const [itemsRecalled, setItemsRecalled] = useState(true)

  useEffect(() => {
    beforeContinue(() => async () => {
      setFormInputs(itemsRecalled)
    })
  }, [itemsRecalled])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
      <Text translation="profileRecollection.instructions" variant="h5" />
      {/* TODO: use markdown file */}
      {/* TODO: add discussion prompt */}

      {/* TODO: make checkboxes appear later */}
      <Divider sx={{ pt: 3 }} />
      <Stimulus context="profileRecollection" setData={setItemsRecalled} />
    </Box>
  )
}
ProfileRecollection.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default ProfileRecollection
