/* eslint max-len: "off" */

const get = key => sessionStorage.getItem(key)
const set = (key, value) => sessionStorage.setItem(key, value)

const getInt = key => parseInt(get(key))

const getJson = key => JSON.parse(get(key))
const setJson = (key, value) => set(key, JSON.stringify(value))

// Array[String]: the last stage's path variable (route) at which the user pressed the 'Continue' button
// - Ensures the user cannot press the continue button twice by refreshing the browser
// - Prevents the user from going back to a previous stage using the browser's back button
// - So long as the user is in the same browser window (tab), they will not lose progress
// ex: [ "introduction" ]
const CONTINUED = 'continuedStages'
const getContinued = () => getJson(CONTINUED)
export const setContinued = continuedStages => setJson(CONTINUED, continuedStages)
export const isContinued = stage => getContinued().includes(stage.route)
export const addContinued = stage => setContinued([...getContinued(), stage.route])

// Int: the user's player ID from KF API
// ex: 1
const LOCAL_PLAYER_ID = 'playerId'
export const getLocalPlayerId = () => getInt(LOCAL_PLAYER_ID)
export const setLocalPlayerId = playerId => set(LOCAL_PLAYER_ID, playerId)

// Object: the round details from KF API stored as a JSON string
// ex:
//    {
//      id: 6,
//      intervention: "key",
//      player_ids: [1, 2],
//      start_time: "2022-04-18T18:16:17.227Z",
//      stimulus_location: "fileName",
//      stimulus_tag: "tag"
//    }
const ROUND = 'round'
export const getRound = () => getJson(ROUND)
export const setRound = round => setJson(ROUND, round)

// String: the base URI path for the round
// ex: "/round/1"
const ROUND_PATH = 'roundPath'
export const getBasePath = () => get(ROUND_PATH)
export const setBasePath = roundPath => set(ROUND_PATH, roundPath)

// Int: the participation id from KF API
// ex: 1
const ROUND_PLAYER_ID = 'roundPlayerId'
export const getRoundPlayerId = () => getInt(ROUND_PLAYER_ID)
export const setRoundPlayerId = roundPlayerId => set(ROUND_PLAYER_ID, roundPlayerId)

// String: the full stimulus from Github - parsed from markdown, but not formatted
// ex: "\"# The CTO at a Fictitious Company Needs Your Help\\n\\n## Instructions\\n_Please read the following fictitious example. ..."
const ROUND_STIMULUS = 'roundStimulus'
export const getRoundStimulus = () => get(ROUND_STIMULUS)
export const setRoundStimulus = roundStimulus => set(ROUND_STIMULUS, roundStimulus)

// Object: representing assignments of versions of the stimulus to player IDs, where the keys are the Integer player IDs and the values are the Integer stimulus versions (stored as a JSON string)
// ex:
//    {
//      2: 1
//      4: 2
//    }
const STIMULUS_ASSIGNMENTS = 'stimulusAssignments'
export const getStimulusAssignments = () => getJson(STIMULUS_ASSIGNMENTS)
export const setStimulusAssignments = stimulusAssignments => setJson(STIMULUS_ASSIGNMENTS, stimulusAssignments)

// Array[Object]: ordered list of stage objects representing treatment for this round's intervention (stored as a JSON string)
// ex:
//    [
//      { key: 'introduction', route: 'introduction' },
//      { key: 'initialRead', route: 'read' },
//      { key: 'initialDiscussion', route: 'discuss', durationInSeconds: 480 }
//    ]
const TREATMENT = 'treatment'
export const getTreatment = () => getJson(TREATMENT)
export const setTreatment = treatment => setJson(TREATMENT, treatment)

// TODO: if we want to reset any user's session and ensure they start over the activity, we can do it here
export const start = (baseRoundPath, participationData) => {
  setBasePath(baseRoundPath)
  setLocalPlayerId(participationData.player_id)
  setRoundPlayerId(participationData.id)
  if (!getContinued()) setContinued([])
}
export const end = () => {
  setContinued([])
  setRound(null)
  setRoundPlayerId(null)
  setStimulusAssignments(null)
  setTreatment(null)
}
