import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// internal helpers
import { Box, Divider } from '@mui/material'
import { asHtml } from '../../helpers/markdown/presenter'
import { getRecollectionPromptContent, getTaggedRoundChecklistContent, parseRepoContent } from '../../requests/stimuli'
import { raiseError } from '../../components/layout/ErrorBoundary'

// JS dependencies

// internal React Components
import Loading from '../../components/Loading'
import Checklist from '../../components/Checklist'
import Text from '../../components/Text'

const CriteriaRecollection = ({ beforeContinue, setFormInputs }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [checklistContent, setChecklistContent] = useState('')
  const [recollectionPrompt, setRecollectionPrompt] = useState('')
  const [itemsRecalled, setItemsRecalled] = useState(true)

  useEffect(() => {
    const onError = () => setError('common.errors.default')
    const onFinally = () => setLoading(false)

    const onSuccessChecklist = response => (
      setChecklistContent(
        parseRepoContent(
          response.data.content,
        ),
      )
    )
    getTaggedRoundChecklistContent(onSuccessChecklist, onError, onFinally)

    const onSuccessPrompt = response => (
      setRecollectionPrompt(
        parseRepoContent(
          response.data.content,
        ),
      )
    )
    getRecollectionPromptContent(onSuccessPrompt, onError, onFinally)
  }, [])

  useEffect(() => {
    beforeContinue(() => async () => {
      setFormInputs(itemsRecalled)
    })
  }, [itemsRecalled])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
      <Text>
        {asHtml(recollectionPrompt)}
      </Text>

      {/* TODO: make checkboxes appear later */}
      <Divider sx={{ pt: 3 }} />
      <Checklist context="criteriaRecollection" setData={setItemsRecalled} content={checklistContent} />
    </Box>
  )
}
CriteriaRecollection.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default CriteriaRecollection
