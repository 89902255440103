import React, { useEffect, useState } from 'react'

// JS dependencies
import { useParams } from 'react-router-dom'

// internal helpers
import { raiseError } from '../components/layout/ErrorBoundary'
import * as session from '../helpers/session'
import * as kfApi from '../requests/kfApi'

// internal React Components
import Centered from '../components/Centered'
import EmailSignIn from '../components/EmailSignIn'
import Loading from '../components/Loading'
import treatments from '../constants/treatments'

const getRound = async (roundId, onSuccess, onError, onFinally) => {
  kfApi.getRound(roundId)
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally)
}

const RoundEntry = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [treatment, setTreatment] = useState([])
  const params = useParams()

  useEffect(async () => {
    const onSuccess = response => {
      session.setRound(response.data)
      const intervention = treatments[response.data.intervention]
      setTreatment(intervention)
      session.setTreatment(intervention)
    }
    const onError = errorResponse => {
      // This state will be checked to raise an error
      setError(errorResponse?.response?.status === 404
        ? 'roundEntry.errors.roundNotFound'
        : 'common.errors.default')
    }
    const onFinally = () => setLoading(false)
    await getRound(
      params.roundId,
      onSuccess,
      onError,
      onFinally,
    )
  }, [])

  // Show error if round call fails and exit out of this component
  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Centered fillY>
      <EmailSignIn {...{ treatment }} />
    </Centered>
  )
}
export default RoundEntry
