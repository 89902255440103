import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { FeedbackOutlined } from '@mui/icons-material'

// internal helpers
import { asHtml } from '../../helpers/markdown/presenter'
import { getFeedbackPrompt, parseRepoContent } from '../../requests/stimuli'

// internal React Components
import Centered from '../../components/Centered'
import Loading from '../../components/Loading'
import Text from '../../components/Text'
import { raiseError } from '../../components/layout/ErrorBoundary'

const DynamicTextFeedback = ({ beforeContinue, setFormInputs }) => {
  const [prompt, setPrompt] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    const onSuccess = response => setPrompt(parseRepoContent(response.data.content))
    const onError = () => setError(true)
    const onFinally = () => setLoading(false)
    getFeedbackPrompt(onSuccess, onError, onFinally)
  }, [])

  useEffect(() => {
    beforeContinue(() => async () => {
      setFormInputs({ feedback })
    })
  }, [feedback])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <FeedbackOutlined fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormLabel id="feedback-label">
        <Text>{asHtml(prompt)}</Text>
      </FormLabel>
      <TextField
        fullWidth
        id={prompt.dataKey}
        label={prompt.placeholder}
        multiline
        rows={8}
        margin="dense"
        value={feedback}
        onChange={e => setFeedback(e.target.value)}
      />
    </Box>
  )
}
DynamicTextFeedback.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default DynamicTextFeedback
