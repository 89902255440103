import React from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { Box, Divider } from '@mui/material'

// internal React Components
import Centered from '../../components/Centered'
import CountdownTimer from '../../components/CountdownTimer'
import Stimulus from '../../components/Stimulus'
import Text from '../../components/Text'

const PostInterventionDiscussion = ({ durationInSeconds }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
    <Centered>
      <CountdownTimer timeInSeconds={durationInSeconds} />

      <Text translation="postInterventionDiscussion.rereadBelow" sx={{ py: 3 }} />
    </Centered>

    <Divider />
    <Stimulus context="partialProfile" />
  </Box>
)
PostInterventionDiscussion.propTypes = {
  durationInSeconds: PropTypes.number.isRequired,
}
export default PostInterventionDiscussion
