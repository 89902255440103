import React from 'react'

import Link from '@mui/material/Link'

import Text from '../components/Text'

const Sorry = () => (
  <>
    <Text>You dont have to go home but ya cant stay here!</Text>
    <Text><Link href="/round/abcxyz">Go Home</Link></Text>
  </>
)
export default Sorry
