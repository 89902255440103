import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import { Box, Divider } from '@mui/material'

// internal helpers
import { getDecisionRecollectionContent, parseRepoContent } from '../../requests/stimuli'
import * as session from '../../helpers/session'

// internal React Components
import Loading from '../../components/Loading'
import Stimulus from '../../components/Stimulus'
import Text from '../../components/Text'
import { raiseError } from '../../components/layout/ErrorBoundary'

const DecisionRecollection = ({ beforeContinue, setFormInputs }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [itemsRecalled, setItemsRecalled] = useState(true)

  useEffect(() => {
    const onSuccess = response => session.setRoundStimulus(parseRepoContent(response.data.content))
    const onError = () => setError(true)
    const onFinally = () => setLoading(false)
    getDecisionRecollectionContent(onSuccess, onError, onFinally)
  }, [])

  useEffect(() => {
    beforeContinue(() => async () => {
      setFormInputs(itemsRecalled)
    })
  }, [itemsRecalled])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
      <Text translation="decisionRecollection.instructions" variant="h5" />
      <Text translation="decisionRecollection.helpText" variant="body2" />

      <Divider sx={{ pt: 3 }} />
      <Stimulus context="selection" setData={setItemsRecalled} />
    </Box>
  )
}
DecisionRecollection.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default DecisionRecollection
