import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// internal helpers
import { asHtml } from '../../helpers/markdown/presenter'
import { getInitialDiscussionPromptContent, parseRepoContent } from '../../requests/stimuli'
import { raiseError } from '../../components/layout/ErrorBoundary'

// internal React Components
import Centered from '../../components/Centered'
import CountdownTimer from '../../components/CountdownTimer'
import Loading from '../../components/Loading'
import Text from '../../components/Text'

const InitialDiscussion = ({ durationInSeconds }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [discussionPrompt, setDiscussionPrompt] = useState('')

  useEffect(() => {
    const onSuccess = response => setDiscussionPrompt(parseRepoContent(response.data.content))
    const onError = () => setError('common.errors.default')
    const onFinally = () => setLoading(false)
    getInitialDiscussionPromptContent(
      onSuccess,
      onError,
      onFinally,
    )
  }, [])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Centered>
      <CountdownTimer timeInSeconds={durationInSeconds} />
      <Text>
        {asHtml(discussionPrompt)}
      </Text>
    </Centered>
  )
}
InitialDiscussion.propTypes = {
  durationInSeconds: PropTypes.number.isRequired,
}
export default InitialDiscussion
