import React from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { Route, Routes } from 'react-router-dom'
import {
  Container,
  Paper,
} from '@mui/material'

// Internal JS Helpers
import { stageList } from '../constants/stages'

// Internal React Components
import continuable from '../components/flowManagement/continuable'
import Goodbye from './stages/Goodbye'
import measurable from '../components/dataManagement/measurable'
import NavBar from '../components/layout/NavBar'

const Layout = ({ children }) => (
  <Container maxWidth="lg" sx={{ minHeight: 'fit-content', height: window.innerHeight - 200 }}>
    <NavBar />
    <Paper
      square
      elevation={6}
      sx={{
        p: 3,
        minHeight: 'fit-content',
      }}
    >
      {children}
    </Paper>
  </Container>
)
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const StageComponent = ({ stage }) => {
  const Component = measurable(stage.key, continuable(stage))
  return <Component />
}
StageComponent.propTypes = {
  stage: PropTypes.oneOf(stageList).isRequired,
}

const Stages = () => (
  <Layout>
    <Routes>
      <Route exact path="end" element={<Goodbye />} key="goodbye" />
      {
        stageList.map(stage => (
          <Route path={stage.route} element={<StageComponent stage={stage} />} key={stage.key} />
        ))
      }
    </Routes>
  </Layout>
)

export default Stages
