import React, { useState, useEffect } from 'react'

// internal helpers
import { getTaggedRoundStimulusContent, parseRepoContent } from '../../requests/stimuli'
import * as session from '../../helpers/session'

// internal React Components
import Loading from '../../components/Loading'
import Stimulus from '../../components/Stimulus'
import { raiseError } from '../../components/layout/ErrorBoundary'

const InitialRead = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const onSuccess = response => session.setRoundStimulus(parseRepoContent(response.data.content))
    const onError = () => setError(true)
    const onFinally = () => setLoading(false)
    getTaggedRoundStimulusContent(onSuccess, onError, onFinally)
  }, [])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Stimulus context="partialProfile" />
  )
}
export default InitialRead
