/* eslint max-len: "off" */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { useNavigate } from 'react-router-dom'

// internal helpers
import * as session from '../../helpers/session'
import * as socket from '../../helpers/socket'

// Internal React Components
import Centered from '../Centered'
import ContinueButton from './ContinueButton'

class NotContinuable extends Error { }

// for currentStage structure, see stages constants file
const continuable = (currentStage, sandbox = false) => {
  // react component is stored in currentStage object
  const WrappedComponent = currentStage.component

  // Here is our new component with continuable functionality
  const ContinuableComponent = ({ setRequestContinueTime, sendStageData, ...props }) => {
    const navigate = useNavigate()

    // if user refreshes browser window, ensure "continue" button doesn't reset
    const alreadyContinued = session.isContinued(currentStage)
    const [playerReady, setPlayerReady] = useState(alreadyContinued)

    // before the player can request to continue, we sometimes want to handle something - so the stage is paused
    const [paused, pauseStage] = useState(false)

    // we may want to do some checks/validate form inputs before we allow the player to continue
    // this callback function allows us to deny the player's request to continue
    const [beforeContinue, defineBeforeContinueCallbackFn] = useState(() => async () => { })

    // get the next stage in the treatment
    const treatment = session.getTreatment()
    const currentStageIndex = treatment.findIndex(stage => stage.key === currentStage.key)
    const nextStage = treatment[currentStageIndex + 1]

    // when the player requests to continue, we notify all players in the round through web sockets
    const emitContinueEvent = () => {
      const cleanupAndMoveOn = () => {
        sendStageData()
        navigate(session.getBasePath() + nextStage.route)
      }

      // we also register a listener for the same event => we can listen for other players' reqyests to continue
      socket.requestToContinue(
        currentStage.route,
        // and we pass a callback function to that listener - a function for when all players are ready to continue
        () => cleanupAndMoveOn(),
      )
    }

    useEffect(() => {
      if (sandbox) return
      // if the player's stage status is 'ready' on page load, then it is due to a browser refresh and we should handle it as such
      if (playerReady) {
        socket.handleBrowserRefresh()
        emitContinueEvent()
      }
    }, [])

    const requestToContinue = async event => {
      event.preventDefault()
      setPlayerReady(true)

      await beforeContinue()
        .then(() => {
          // this timestamp is for data collection
          setRequestContinueTime(new Date())
          // add this stage to the browser session's list of continued stages
          // so that navigating to previous stages will fail + forward the player to the latest stage
          session.addContinued(currentStage)

          if (!sandbox) {
          // notify all other players via web sockets
            emitContinueEvent()
          // TODO: scroll to top
          }
        })
        .catch(error => {
          // form validation failures will raise a NotContinuable in the beforeContinue callback function
          if (error instanceof NotContinuable) {
            setPlayerReady(false)
          } else {
            throw error
          }
        })
    }

    return (
      <>
        <WrappedComponent
          preventContinue={pauseStage}
          beforeContinue={defineBeforeContinueCallbackFn}
          failContinue={() => { throw new NotContinuable() }}
          sandbox={sandbox}
          durationInSeconds={currentStage.durationInSeconds}
          {...props}
        />

        <Centered sx={{ mt: 3 }}>
          <ContinueButton
            customText={currentStage.customContinueText}
            waiting={paused || playerReady}
            onClick={requestToContinue}
            type="submit"
          />
        </Centered>
      </>
    )
  }
  ContinuableComponent.propTypes = {
    setRequestContinueTime: PropTypes.func.isRequired,
    sendStageData: PropTypes.func.isRequired,
  }
  // add display name for debugging in browser
  ContinuableComponent.displayName = 'ContinuableComponent'
  return ContinuableComponent
}
export default continuable
