import React, { useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import { AccountCircle } from '@mui/icons-material'
import { Box, TextField, Alert, Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight'
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft'

// internal helpers
import { raiseError } from './layout/ErrorBoundary'
import * as session from '../helpers/session'
import * as kfApi from '../requests/kfApi'
import treatments from '../constants/treatments'

// internal React Components
import Text from './Text'

const authenticateParticipation = (params, onSuccess, onError, onFinally) => {
  kfApi.authenticateParticipation(params)
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally)
}

// TODO: do not allow more than one player to join with the same email
const EmailSignIn = ({ treatment }) => {
  const t = useIntl()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const { roundId } = useParams()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  const [searchParams] = useSearchParams()
  const defaultValue = searchParams.get('email')

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    setError(null)
    const email = event.target[0].value

    if (!email) {
      setError('common.errors.required')
      setLoading(false)
      return
    }

    const onSuccess = response => {
      const baseRoundPath = `${pathname.replace(/\/$/, '')}/`
      session.start(baseRoundPath, response.data)

      navigate(baseRoundPath + treatment[0].route)
    }
    const onError = errorResponse => {
      if (errorResponse?.response?.status === 404) {
        setError('roundEntry.emailSignIn.errors.participationNotFound')
      } else {
        raiseError()
      }
    }
    const onFinally = () => setLoading(false)
    authenticateParticipation(
      { email, round: roundId },
      onSuccess,
      onError,
      onFinally,
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id="email"
          label={t.formatMessage({ id: 'roundEntry.emailSignIn.placeholder' })}
          variant="standard"
          error={!!error}
          defaultValue={defaultValue}
        />
      </Box>

      <LoadingButton sx={{ mb: 3 }} type="submit" loading={loading} variant="outlined">
        {t.formatMessage({ id: 'roundEntry.emailSignIn.actions.begin' })}
      </LoadingButton>

      {defaultValue && (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <TurnSlightRightIcon />
          <Text variant="body2" sx={{ mt: 0.8 }} translation="roundEntry.emailSignIn.actions.prompt" />
          <TurnSlightLeftIcon />
        </Box>
      )}

      <Container maxWidth="sm">
        {!!error && <Alert severity="error">{t.formatMessage({ id: error })}</Alert>}
      </Container>
    </form>
  )
}
EmailSignIn.propTypes = {
  treatment: PropTypes.oneOf(Object.values(treatments)).isRequired,
}
export default EmailSignIn
