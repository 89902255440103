import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import { BrowserRouter } from 'react-router-dom'

import Routes from './Routes'
import messages from './locales/index'
import ErrorBoundary from './components/layout/ErrorBoundary'

// register any socket event listeners
import './helpers/socket'

const defaultLocale = 'en'

const App = () => {
  const [locale, setLocale] = useState(defaultLocale) // eslint-disable-line no-unused-vars

  return (
    <IntlProvider
      messages={flatten(messages[locale])}
      key={locale}
      locale={locale}
      defaultLocale={defaultLocale}
    >
      <ErrorBoundary>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ErrorBoundary>
    </IntlProvider>
  )
}
export default App
