import React, { useState, useEffect } from 'react'

// JS dependencies
import { Box } from '@mui/material'

// internal helpers
import { asHtml } from '../../helpers/markdown/presenter'
import { getInterventionContent, parseRepoContent } from '../../requests/stimuli'
import { raiseError } from '../../components/layout/ErrorBoundary'

// internal React Components
import Loading from '../../components/Loading'
import Text from '../../components/Text'

const DeliverIntervention = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [interventionText, setInterventionText] = useState('')

  useEffect(() => {
    const onSuccess = response => setInterventionText(parseRepoContent(response.data.content))
    const onError = () => setError(true)
    const onFinally = () => setLoading(false)
    getInterventionContent(onSuccess, onError, onFinally)
  }, [])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
      <Text>
        {asHtml(interventionText)}
      </Text>
    </Box>
  )
}
export default DeliverIntervention
