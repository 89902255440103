import socketIOClient from 'socket.io-client'
import * as session from './session'
import * as events from '../constants/socketEvents'

const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT)

// Room setup
export const initializeParticipation = () => {
  const localPlayerId = session.getLocalPlayerId()
  const round = session.getRound()

  socket.emit(
    events.INITIALIZE_PARTICIPATION,
    { roundId: round.id, playerIds: round.player_ids, localPlayerId },
  )
}

export const whenOtherPlayersHaveJoined = func => {
  socket.on(events.ROUND_READY, ({ stimulusAssignments }) => {
    session.setStimulusAssignments(stimulusAssignments)

    func()
  })
}

// Stage flow management
export const requestToContinue = (stage, callback) => {
  socket.emit(events.REQUEST_TO_CONTINUE, { stage })

  socket.on(events.continueToNextStage(stage), () => {
    callback()
  })
}

// Handle Browser Refresh
export const handleBrowserRefresh = () => {
  const localPlayerId = session.getLocalPlayerId()
  const round = session.getRound()
  if (!localPlayerId || !round) return

  socket.emit(
    events.REFRESH,
    { roundId: round.id, localPlayerId },
  )
}
socket.on('connect', () => handleBrowserRefresh())
