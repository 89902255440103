import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { RateReviewRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const ExplainCriteria = ({ beforeContinue, failContinue, setFormInputs }) => {
  const t = useIntl()

  const [formErrors, setFormErrors] = useState({})
  const [explanation, setExplanation] = useState('')

  const validateForm = async () => {
    if (!explanation) {
      setFormErrors({
        explanation: !explanation,
      })
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()

      setFormInputs({ explanation })
    })
  }, [explanation])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <RateReviewRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormLabel id="explanation-label">
        <Text translation="explainCriteria.form.explanation.label" />
      </FormLabel>
      <TextField
        error={formErrors.explanation}
        fullWidth
        id="explanation"
        label={t.formatMessage({ id: 'explainCriteria.form.explanation.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={explanation}
        onChange={e => setExplanation(e.target.value)}
      />
    </Box>
  )
}
ExplainCriteria.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default ExplainCriteria
