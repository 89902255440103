import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { RateReviewRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const TagFeedback = ({ beforeContinue, failContinue, setFormInputs }) => {
  const t = useIntl()

  const [formErrors, setFormErrors] = useState({})
  const [drawParallels, setDrawParallels] = useState('')
  const [behaviorChange, setBehaviorChange] = useState('')

  const validateForm = async () => {
    if (!drawParallels || !behaviorChange) {
      setFormErrors({
        drawParallels: !drawParallels,
        behaviorChange: !behaviorChange,
      })
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()

      setFormInputs({
        drawParallels,
        behaviorChange,
      })
    })
  }, [drawParallels, behaviorChange])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <RateReviewRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormLabel id="drawParallels-label">
        <Text translation="tagFeedback.form.drawParallels.label" />
      </FormLabel>
      <TextField
        error={formErrors.drawParallels}
        fullWidth
        id="drawParallels"
        label={t.formatMessage({ id: 'tagFeedback.form.drawParallels.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={drawParallels}
        onChange={e => setDrawParallels(e.target.value)}
      />

      <br />

      <FormLabel id="self-improvement-label">
        <Text translation="tagFeedback.form.behaviorChange.label" />
      </FormLabel>
      <TextField
        error={formErrors.behaviorChange}
        fullWidth
        id="behaviorChange"
        label={t.formatMessage({ id: 'tagFeedback.form.behaviorChange.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={behaviorChange}
        onChange={e => setBehaviorChange(e.target.value)}
      />
    </Box>
  )
}
TagFeedback.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default TagFeedback
