import React, { useEffect, useState } from 'react'

// JS Dependencies
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

// internal JS helpers
import treatments from '../../constants/treatments'
import * as kfApi from '../../requests/kfApi'

// internal React Components
import Text from '../Text'
import Loading from '../Loading'

const getActivityAudit = (onSuccess, onError, onFinally) => {
  kfApi.auditActivity()
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally)
}

const Treatments = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [interventionKeys, setInterventionKeys] = useState([])

  useEffect(() => {
    const onSuccess = response => setInterventionKeys(response.data.intervention_keys)
    const onError = errorResponse => {
      // This state will be checked to raise an error
      setError(errorResponse?.response?.status === 404
        ? 'roundEntry.errors.roundNotFound'
        : 'common.errors.default')
    }
    const onFinally = () => setLoading(false)
    getActivityAudit(
      onSuccess,
      onError,
      onFinally,
    )
  }, [])

  // Show error if round call fails and exit out of this component
  if (error) return <Text translation={error} />
  if (loading) return <Loading />

  return (
    <>
      <Text variant="h4">Treatments</Text>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>In UI src/constants/treatments.js</TableCell>
              <TableCell align="right">In API</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">
                {Object.keys(treatments).sort().map(treatment => (
                  <Text key={treatment}>{treatment}</Text>
                ))}
              </TableCell>
              <TableCell align="right">
                {interventionKeys.sort().map(interventionKey => (
                  <Text key={interventionKey}>{interventionKey}</Text>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default Treatments
