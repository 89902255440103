import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Centered from '../Centered'
import Text from '../Text'

// Components use this function to raise errors in order to make it clear
// that errors are being caught by this component
export const raiseError = (error = 'common.errors.default') => { throw new Error(error) }

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    console.error('componentDidCatch', { error, errorInfo }) // eslint-disable-line no-console
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    return error
      ? (
        <Centered fillY>
          <Text>
            <FormattedMessage
              id={error?.message}
              defaultMessage="Something went wrong."
            />
          </Text>
        </Centered>
      )
      : children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node,
}
ErrorBoundary.defaultProps = {
  children: '',
}
export default ErrorBoundary
