import React, { useState } from 'react'

// JS Dependencies
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Tab,
  TextField,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// internal JS helpers
import { stageList } from '../../constants/stages'
import * as session from '../../helpers/session'

// internal React Components
import Text from '../Text'
import measurable from '../dataManagement/measurable'
import continuable from '../flowManagement/continuable'

const EXCLUSIONS = [
  'goodbye',
]
const Preview = () => {
  const viewableStages = stageList.filter(stage => !EXCLUSIONS.includes(stage.key))
  const [value, setValue] = useState(viewableStages[0].key)
  const [stimulusTag, setStimulusTag] = useState('demo.4')

  const handleChange = (event, newValue) => {
    session.setContinued([])
    setValue(newValue)
  }

  // set mock data
  session.setTreatment([])
  session.setRound({ stimulus_tag: stimulusTag, stimulus_location: 'stimulus' })
  session.setStimulusAssignments({ [1]: 1 }) // eslint-disable-line no-useless-computed-key
  // TODO: allow select view for other player IDs
  session.setLocalPlayerId(1)
  session.setContinued([])

  const loadContentWithStimulusTag = event => {
    event.preventDefault()
    const stimulusTagInput = event.target[0].value
    if (!stimulusTagInput) return
    setStimulusTag(stimulusTagInput)
  }

  const sandbox = true
  return (
    <Container maxWidth="xl" sx={{ p: 3 }}>
      <Button variant="text" href="/health">Back to health checks</Button>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Text variant="h4">Treatment Stages</Text>
        </Grid>
        <Grid item xs={3}>
          <form onSubmit={loadContentWithStimulusTag}>
            <TextField size="small" id="stimulus_tag" label="stimulus_tag" variant="filled" defaultValue={stimulusTag} />
            <br />
            <Button type="submit" variant="text" size="small">Set Stimulus Tag</Button>
          </form>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="right" border="1px dashed grey" p={2}>
            <Text variant="body2">
              Open developer tools to view data collected during this stage.
              <br />
              <Link href="https://developer.chrome.com/docs/devtools/open/">See more</Link>
              <br />
              <div>
                Note that a stage&apos;s
                <code> endedAt </code>
                timestamp will always be the same as a stage&apos;s
                <code> requestedContinueAt </code>
                timestamp when in sandbox mode.
              </div>
            </Text>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
        <TabContext value={value}>

          <TabList orientation="vertical" onChange={handleChange}>
            {viewableStages.map(stage => (
              <Tab key={stage.key} label={stage.key.match(/[A-Za-z][a-z]*/g).join(' ')} value={stage.key} />
            ))}
          </TabList>

          {viewableStages.map(stage => {
            const StageComponent = measurable(stage.key, continuable(stage, sandbox), sandbox)
            return (
              <TabPanel key={stage.key} value={stage.key} sx={{ width: '100%' }}>
                <Paper elevation={10} sx={{ p: 2 }}>
                  <StageComponent />
                </Paper>
              </TabPanel>
            )
          })}
        </TabContext>
      </Box>
    </Container>
  )
}
export default Preview
