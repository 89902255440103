import React from 'react'

// JS Dependencies
import { LinearProgress } from '@mui/material'

// internal React Components
import Centered from './Centered'

const Loading = () => (
  <Centered fillY>
    <div>
      <LinearProgress />
    </div>
  </Centered>
)
export default Loading
