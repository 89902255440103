import React from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import { LoadingButton } from '@mui/lab'
import { useIntl } from 'react-intl'

const ContinueButton = ({ waiting, onClick, customText, ...rest }) => {
  const t = useIntl()

  return (
    <LoadingButton
      variant="contained"
      color="success"
      size="large"
      onClick={onClick}
      loading={waiting}
      loadingIndicator={t.formatMessage({ id: 'common.actions.waiting' })}
      {...rest}
    >
      {customText || t.formatMessage({ id: 'common.actions.continue' })}
    </LoadingButton>
  )
}
ContinueButton.propTypes = {
  waiting: PropTypes.bool,
  onClick: PropTypes.func,
  customText: PropTypes.string,
}
ContinueButton.defaultProps = {
  waiting: true,
  onClick: () => { },
  customText: 'Continue',
}
ContinueButton.defaultProps = {
  customText: null,
}
export default ContinueButton
