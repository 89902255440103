import client from './middlewareClient'

// POST request to Express middleware, which will handle external calls
// ./server/middleware.js
const request = config => client.post(
  // this is a one-size-fits-all endpoint in the middleware to handle all kfApi external requests
  '/middleware/kfApi',
  // the content of `data` will become the config for the external axios call from the middleware
  // ex: {
  //   method: 'get' | 'put' | 'post' | 'delete',
  //   url: '/path/to/endpoint',
  //   data: {}, <- dict for requests that allow req body
  //   params: {}, <- dict for requests that allow query params
  // }
  { data: config },
)

// Custom Endpoint Functions for KF API
export const auditActivity = () => request({
  method: 'get',
  url: '/audit/activity/1',
})
export const authenticateParticipation = params => request({
  method: 'get',
  url: '/round_players/authenticate',
  params,
})
export const getRound = roundId => request({
  method: 'get',
  url: `/rounds/${roundId}`,
})
export const readData = params => request({
  method: 'get',
  url: '/round_players/read_data',
  params,
})
export const sendData = ({ roundPlayerId, data }) => request({
  method: 'put',
  url: `/round_players/${roundPlayerId}`,
  data,
})
export const startRound = (roundId, startTime) => request({
  method: 'put',
  url: `rounds/${roundId}/start`,
  data: { start_time: startTime },
})
export const endRound = (roundId, endTime) => request({
  method: 'put',
  url: `/rounds/${roundId}/end`,
  data: { end_time: endTime },
})
