import React from 'react'

// JS Dependencies
import { Box } from '@mui/material'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const Discuss = () => (
  <Centered>
    <Text translation="aboutHP.watchVideo" />

    <Box sx={{ m: 5 }}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/k9atH0HciVE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </Box>
  </Centered>
)

export default Discuss
