import React from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

export const SUCCESS = 'success'
export const ERROR = 'error'
export const INFO = 'info'
export const WARNING = 'warning'

const AlertModal = ({ open, onClose,
  titleText,
  buttonType, buttonText,
  children }) => (

    <Dialog onClose={onClose} open={open}>
      {titleText && <DialogTitle>{titleText}</DialogTitle>}
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color={buttonType} onClick={onClose}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
)
AlertModal.defaultProps = {
  titleText: '',
  buttonType: 'info',
  buttonText: 'OK',
}
AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  buttonType: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
}
export default AlertModal
