import * as session from '../session'

export const parseBulletsIntoChecklist = (markdown = '') => (
  markdown.trim().split('\n').map(line => {
    if (!line.startsWith('- ')) return line

    const bullet = line.trim().split('- ').slice(1).join('- ')
    return `[]${bullet}`
  })
)

const stimulusAssignment = () => (
  session.getStimulusAssignments()[session.getLocalPlayerId().toString()]
)

export const parseStimulusIntoList = (fullStimulus = '') => {
  const infoLines = fullStimulus
    .split('<!--- INFO_BEGIN --->')
    .pop()
    .trim()
    .split('<!--- INFO_END --->')[0]
    .trim()

  return infoLines.split('\n').map(line => {
    if (!line.startsWith('- [')) return line

    const lineText = line.split(']').pop()
    return `[]${lineText.trim()}`
  })
}

export const parseStimulusByPlayerId = (fullStimulus = '') => (
  fullStimulus.split('\n').map(line => {
    if (!line.startsWith('- [')) return line

    const [playerType, lineText] = line.split(']')
    return playerType.includes(stimulusAssignment()) ? `- ${lineText.trim()}` : null
  }).filter(x => x).join('\n')
)

export const returnFullText = fullStimulus => fullStimulus
