import React from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { Box } from '@mui/material'

const Centered = ({ children, sx, fillY }) => (
  <Box
    sx={{
      mx: 'auto',
      width: '100%',
      height: fillY ? '80vh' : '',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...sx,
    }}
  >
    <div>
      {children}
    </div>
  </Box>
)
Centered.propTypes = {
  children: PropTypes.node.isRequired,
  fillY: PropTypes.bool,
  sx: PropTypes.object, // eslint-disable-line react/forbid-prop-types, react/require-default-props
}
Centered.defaultProps = {
  fillY: false,
}
export default Centered
