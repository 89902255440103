// ADMIN: Decide the length of time in seconds for each stage.

import AboutHP from '../pages/stages/AboutHP'
import ConversationMetrics from '../pages/stages/ConversationMetrics'
import CriteriaRecollection from '../pages/stages/CriteriaRecollection'
import DecisionRecollection from '../pages/stages/DecisionRecollection'
import DeliverIntervention from '../pages/stages/DeliverIntervention'
import DiscussionRecollection from '../pages/stages/DiscussionRecollection'
import DynamicTextFeedback from '../pages/stages/DynamicTextFeedback'
import ExplainCriteria from '../pages/stages/ExplainCriteria'
import Feedback from '../pages/stages/Feedback'
import Feelings from '../pages/stages/Feelings'
import FullProfile from '../pages/stages/FullProfile'
import Goodbye from '../pages/stages/Goodbye'
import InitialDecision from '../pages/stages/InitialDecision'
import InitialDiscussion from '../pages/stages/InitialDiscussion'
import InitialRead from '../pages/stages/InitialRead'
import Introduction from '../pages/stages/Introduction'
import PostInterventionDecision from '../pages/stages/PostInterventionDecision'
import PostInterventionDiscussion from '../pages/stages/PostInterventionDiscussion'
import LongTextQuestionsForm from '../pages/stages/LongTextQuestionsForm'
import ProfileRecollection from '../pages/stages/ProfileRecollection'
import Selection from '../pages/stages/Selection'
import Strategies from '../pages/stages/Strategies'
import TagFeedback from '../pages/stages/TagFeedback'
import TwineStory from '../pages/stages/TwineStory'

// Note: integers should not be passed to the config directly
// but should have a variable constant which is used/reused.
const SIX_MINUTES = 360
const EIGHT_MINUTES = 480
const FIFTEEN_MINUTES = 900

export const stageList = [
  { // tag
    key: 'tagGame',
    route: 'tagGame',
    component: TwineStory,
  },
  { // generic long text question page
    key: 'longTextQuestionsForm',
    route: 'longTextQuestionsForm',
    component: LongTextQuestionsForm,
  },
  { // dynamic single-question feedback page
    key: 'dynamicTextFeedback',
    route: 'dynamicTextFeedback',
    component: DynamicTextFeedback,
  },
  { // timed multi-player tag discussion
    key: 'postTagDiscussion',
    route: 'postTagDiscussion',
    component: InitialDiscussion,
    durationInSeconds: SIX_MINUTES,
    customContinueText: 'finished discussing',
  },
  { // multi-player tag discussion checklist
    key: 'discussionRecollection',
    route: 'discussionRecollection',
    component: DiscussionRecollection,
  },
  { // multi-player tag decision checklist
    key: 'decisionRecollection',
    route: 'decisionRecollection',
    component: DecisionRecollection,
  },
  { // generic checklist
    key: 'selection',
    route: 'selection',
    component: Selection,
  },
  { // waiting room
    key: 'introduction',
    route: 'introduction',
    component: Introduction,
  },
  { // user-versioned document
    key: 'initialRead',
    route: 'read',
    component: InitialRead,
  },
  { // video conference
    key: 'initialDiscussion',
    route: 'discuss',
    component: InitialDiscussion,
    durationInSeconds: FIFTEEN_MINUTES,
    customContinueText: 'finished discussing',
  },
  { // form
    key: 'initialDecision',
    route: 'respond',
    component: InitialDecision,
  },
  { // slider survey
    key: 'feelings',
    route: 'feelings',
    component: Feelings,
  },
  { // slider survey
    key: 'conversationMetrics',
    route: 'conversationMetrics',
    component: ConversationMetrics,
  },
  { // video presentation
    key: 'aboutHP',
    route: 'aboutHP',
    component: AboutHP,
  },
  // tag game feedback form
  {
    key: 'tagFeedback',
    route: 'tagFeedback',
    component: TagFeedback,
  },
  { // list with checkboxes
    key: 'fullProfile',
    route: 'fullProfile',
    component: FullProfile,
  },
  { // dashboard - how much did you discuss, how does this compare with other teams
    key: 'feedback',
    route: 'reflect',
    component: Feedback,
  },
  { // video presentation
    key: 'strategies',
    route: 'strategies',
    component: Strategies,
  },
  {
    key: 'deliverIntervention',
    route: 'disclosure',
    component: DeliverIntervention,
  },
  { // video conference  - maybe dont do this one?
    key: 'postInterventionDiscussion', // include partial text
    route: 'rediscuss',
    component: PostInterventionDiscussion,
    durationInSeconds: EIGHT_MINUTES,
    customContinueText: 'finished rediscussing',
  },
  {
    key: 'postInterventionDecision',
    route: 'rerespond',
    component: PostInterventionDecision,
  },
  {
    key: 'profileRecollection',
    route: 'recallProfile',
    component: ProfileRecollection,
  },
  {
    key: 'explainCriteria',
    route: 'explain',
    component: ExplainCriteria,
  },
  {
    key: 'criteriaRecollection',
    route: 'recallCriteria',
    component: CriteriaRecollection,
  },
  {
    key: 'goodbye',
    route: 'end',
    component: Goodbye,
  },
]

export const stageMap = Object.fromEntries(
  stageList.map(stage => [stage.key, stage]),
)
