import React from 'react'

// internal React Components
import Decision from '../../components/Decision'

const InitialDecision = props => (
  <Decision
    stage="initialDecision"
    {...props}
  />
)

export default InitialDecision
