import React from 'react'

// JS dependencies
import { AppBar, Box, Breadcrumbs, Toolbar } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

// internal helpers
import { stageList } from '../../constants/stages'
import * as session from '../../helpers/session'

// internal React Components
import Text from '../Text'

const getStageFromPath = pathname => {
  const currentStageRoute = pathname.split('/').pop().replace(/[^a-z0-9]/gi, '')
  const currentStage = stageList.find(stage => stage.route === currentStageRoute)

  return currentStage
}
const getStageIndex = (treatment, stage) => (
  treatment.findIndex(treatmentStage => treatmentStage.key === stage.key)
)

const NavBar = () => {
  const { pathname } = useLocation()

  const currentStage = getStageFromPath(pathname)
  const currentStageIndex = getStageIndex(session.getTreatment(), currentStage)
  const breadcrumbStages = session.getTreatment().slice(0, currentStageIndex + 1)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" sx={{ pb: 1, color: 'white' }} />}
            aria-label="breadcrumb"
            sx={{ pt: 1 }}
            maxItems={10}
          >
            {
              breadcrumbStages.map(stage => (
                <Text
                  key={`stages.${stage.key}`}
                  translation={`stages.${stage.key}`}
                  sx={{ flexGrow: 1, color: 'white' }}
                />
              ))
            }
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default NavBar
