/* eslint-disable no-cond-assign */

// eslint-disable-next-line import/no-mutable-exports
export let isChrome = false

// https://stackoverflow.com/a/11219680

// Written to detect desktop browsers
export const compileBrowserData = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent
  const nAgt = navigator.userAgent
  let browserName = 'Unknown'
  let fullVersion
  let majorVersion
  let nameOffset
  let verOffset

  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
    browserName = 'Opera'
    fullVersion = nAgt.substring(verOffset + 4)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8)
    }

    // In MS Edge, the true version is after "Edg" in userAgent
  } else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
    browserName = 'Microsoft Edge'
    fullVersion = nAgt.substring(verOffset + 4)

    // In MSIE, the true version is after "MSIE" in userAgent
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer'
    fullVersion = nAgt.substring(verOffset + 5)

    // In Chrome, the true version is after "Chrome"
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    isChrome = true
    browserName = 'Chrome'
    fullVersion = nAgt.substring(verOffset + 7)

    // In Safari, the true version is after "Safari" or after "Version"
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari'
    fullVersion = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8)

    // In Firefox, the true version is after "Firefox"
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox'
    fullVersion = nAgt.substring(verOffset + 8)

  // In most other browsers, "name/version" is at the end of userAgent
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1)
          < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset)
    fullVersion = nAgt.substring(verOffset + 1)
  }

  let ix
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1) fullVersion = fullVersion.substring(0, ix)
  if ((ix = fullVersion.indexOf(' ')) !== -1) fullVersion = fullVersion.substring(0, ix)

  majorVersion = parseInt(`${fullVersion}`)
  if (Number.isNaN(majorVersion)) {
    fullVersion = `${parseFloat(navigator.appVersion)}`
    majorVersion = parseInt(navigator.appVersion)
  }

  return {
    browserName,
    fullVersion,
    majorVersion,
    navigatorUserAgent: navigator.userAgent,
    cookiesEnabled: navigator.cookieEnabled,
    language: navigator.language,
  }
}

// This script sets OSName variable as follows:
// "Windows"    for all versions of Windows
// "MacOS"      for all versions of Macintosh OS
// "Linux"      for all versions of Linux
// "UNIX"       for all other UNIX flavors
// "Unknown OS" indicates failure to detect the OS
export const compileOsData = () => {
  let osName = 'Unknown OS'
  if (navigator.userAgent.indexOf('Win') !== -1) osName = 'Windows'
  if (navigator.userAgent.indexOf('Mac') !== -1) osName = 'MacOS'
  if (navigator.userAgent.indexOf('X11') !== -1) osName = 'UNIX'
  if (navigator.userAgent.indexOf('Linux') !== -1) osName = 'Linux'

  return { osName }
}
