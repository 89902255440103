import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { RateReviewRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const Feedback = ({ beforeContinue, failContinue, setFormInputs }) => {
  const t = useIntl()

  const [formErrors, setFormErrors] = useState({})
  const [takeaway, setTakeaway] = useState('')
  const [selfImprovement, setSelfImprovement] = useState('')

  const validateForm = async () => {
    if (!takeaway || !selfImprovement) {
      setFormErrors({
        takeaway: !takeaway,
        selfImprovement: !selfImprovement,
      })
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()

      setFormInputs({
        takeaway,
        selfReportedImpact: selfImprovement,
      })
    })
  }, [takeaway, selfImprovement])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <RateReviewRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormLabel id="takeaway-label">
        <Text translation="feedback.form.takeaway.label" />
      </FormLabel>
      <TextField
        error={formErrors.takeaway}
        fullWidth
        id="takeaway"
        label={t.formatMessage({ id: 'feedback.form.takeaway.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={takeaway}
        onChange={e => setTakeaway(e.target.value)}
      />

      <br />

      <FormLabel id="self-improvement-label">
        <Text translation="feedback.form.selfImprovement.label" />
        <Text translation="feedback.form.selfImprovement.label2" />
      </FormLabel>
      <TextField
        error={formErrors.selfImprovement}
        fullWidth
        id="selfImprovement"
        label={t.formatMessage({ id: 'feedback.form.selfImprovement.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={selfImprovement}
        onChange={e => setSelfImprovement(e.target.value)}
      />
    </Box>
  )
}
Feedback.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default Feedback
