import React from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import { Box, Button, Container, Paper } from '@mui/material'
import { Masonry } from '@mui/lab'

// internal React Components
import Centered from '../components/Centered'
import Text from '../components/Text'
import Treatments from '../components/audit/Treatments'
import TreatmentStages from '../components/audit/TreatmentStages'

const MetricWrapper = ({ children }) => <Paper elevation={10} sx={{ py: 3 }}>{children}</Paper>
MetricWrapper.propTypes = { children: PropTypes.node.isRequired }

const Audit = () => {
  const metrics = [
    <TreatmentStages />,
    <Treatments />,
    <Text>preview all GitHub content loaded as part of the activity</Text>,
    <Text>check current file structure and latest version of GitHub repo</Text>,
  ]

  return (
    <Container maxWidth="xl" sx={{ p: 5 }}>
      <Button variant="text" href="/health/preview">See individual stages preview ...</Button>

      <Centered>
        <Box>
          <Masonry columns={2} spacing={2}>
            {metrics.map((m, i) => <MetricWrapper key={`metric-${i}`}>{m}</MetricWrapper>)}
          </Masonry>
        </Box>
      </Centered>
    </Container>
  )
}
export default Audit
