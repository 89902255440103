import React from 'react'
import PropTypes from 'prop-types'

// internal helpers
import * as parsers from '../helpers/markdown/parser'
import * as presenters from '../helpers/markdown/presenter'
import * as session from '../helpers/session'

// internal React Components
import Text from './Text'

const renderers = {
  fullProfile: { // for admin use
    parse: parsers.returnFullText,
    present: presenters.asHtml,
  },
  partialProfile: { // in-game read-only
    parse: parsers.parseStimulusByPlayerId,
    present: presenters.asHtml,
  },
  profileRecollection: { // in-game form
    parse: parsers.parseStimulusIntoList,
    present: presenters.asCheckboxes,
  },
  selection: { // given bullets in md
    parse: parsers.parseBulletsIntoChecklist,
    present: presenters.asCheckboxes,
  },
}

const Stimulus = ({ context, setData }) => {
  const renderer = renderers[context]
  const parsedStimulus = renderer.parse(session.getRoundStimulus())
  const stimulus = renderer.present(parsedStimulus, setData)

  return (
    <Text sx={{ px: 3 }}>
      {stimulus}
    </Text>
  )
}
Stimulus.propTypes = {
  context: PropTypes.oneOf(['fullProfile', 'partialProfile', 'profileRecollection', 'selection']).isRequired,
  setData: PropTypes.func,
}
Stimulus.defaultProps = {
  setData: () => { },
}
export default Stimulus
