import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { RateReviewRounded } from '@mui/icons-material'

// internal helpers
import { csvStringToObject } from '../helpers/utils'

// internal React Components
import Centered from './Centered'
import Text from './Text'

// Use of this component requires a valid file according to the following rules:
// - The file must exist in kelp_forest-stimul.
// - The file must be a valid CSV, with file extension `.csv`
// - The file must have header row: "dataKey","question","helpText","placeholder"
//
// Example:
//    filename:
//      example.csv
//     file contents:
//      "dataKey","question","helpText","placeholder"
//      "formField","How do you feel?","Answer in one sentence.","I feel..."
//
// Pass CSV file content unparsed directly into this component as prop: rawCsvPrompts.
const DynamicTextPrompts = ({ beforeContinue, failContinue, rawCsvPrompts, setFormInputs }) => {
  const promptObjs = csvStringToObject(rawCsvPrompts)

  const newPromptsObj = makeValue => (
    Object.fromEntries(promptObjs.map(prompt => [prompt.dataKey, makeValue(prompt)]))
  )

  const [formErrors, setFormErrors] = useState({})
  const [formEntries, setFormEntries] = useState(newPromptsObj(() => ''))

  const validateForm = async () => {
    if (!Object.values(formEntries).every(entry => entry)) {
      setFormErrors(newPromptsObj(prompt => !formEntries[prompt.dataKey]))
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()
      setFormInputs(newPromptsObj(prompt => (
        { ...prompt, playerInput: formEntries[prompt.dataKey] })))
    })
  }, [formEntries])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <RateReviewRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      {promptObjs.map(prompt => (
        <>
          <FormLabel id={`${prompt.dataKey}-label`}>
            <Text>{prompt.question}</Text>
            {prompt.helpText && <Text variant="body2">{prompt.helpText}</Text>}
          </FormLabel>
          <TextField
            error={formErrors[prompt.dataKey]}
            fullWidth
            id={prompt.dataKey}
            label={prompt.placeholder}
            multiline
            rows={4}
            margin="dense"
            value={formEntries[prompt.dataKey]}
            onChange={e => setFormEntries(prev => {
              const newState = { ...prev }
              newState[prompt.dataKey] = e.target.value
              return newState
            })}
          />
          <br />
        </>
      ))}
    </Box>
  )
}
DynamicTextPrompts.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
  rawCsvPrompts: PropTypes.string.isRequired,
}
export default DynamicTextPrompts
