import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// internal helpers
import { getRoundPlayerId, getRound, getLocalPlayerId } from '../../helpers/session'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const TwineStory = ({ preventContinue }) => {
  const iframeSize = () => ({ width: '100%', height: window.innerHeight - 240 })

  useEffect(() => {
    preventContinue(true)
  }, [])

  /* When Twine is first ready */
  window.onmessage = event => {
    // eslint-disable-next-line default-case
    switch (event.data) {
      case 'Twine displayed':
        const iframe = document.querySelector('#tag-iframe')
        // playerData is setup.kfData in Twine
        const playerData = {
          playerId: getLocalPlayerId(),
          roundPlayerID: getRoundPlayerId(),
          baseURI: `${process.env.REACT_APP_KELP_FOREST_MIDDLEWARE_BASE_URI}middleware/kfApi`,
        }
        iframe.contentWindow.postMessage(playerData, '*')
        break
      case 'Twine leaf node reached':
        preventContinue(false)
        break
    }
  }

  // Size of iframe changes when window is resized
  window.addEventListener('resize', () => {
    const iframe = document.querySelector('#tag-iframe')
    iframe.width = iframeSize().width
    iframe.height = iframeSize().height
  })

  return (
    <Centered>
      <Text translation="tagGame.playGame" />

      <iframe
        id="tag-iframe"
        src={getRound().stimulus_location}
        width={iframeSize().width}
        height={iframeSize().height}
        title="TAG"
      />

    </Centered>
  )
}
TwineStory.propTypes = {
  preventContinue: PropTypes.func.isRequired,
}

export default TwineStory
