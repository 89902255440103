import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { QuizRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal React Components
import Centered from './Centered'
import Text from './Text'

const Decision = ({ beforeContinue, failContinue, setFormInputs }) => {
  const t = useIntl()

  const [formErrors, setFormErrors] = useState({})
  const [option, chooseOption] = useState(null)
  const [justification, setJustification] = useState('')

  const validateForm = async () => {
    if (!justification || !option) {
      setFormErrors({
        justification: !justification,
        option: !option,
      })
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()

      setFormInputs({
        decision: option,
        justification,
      })
    })
  }, [justification, option])

  const radioButtonOptions = ['A', 'B']
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <QuizRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormControl sx={{ py: 5 }} error={formErrors.option}>
        <FormLabel id="chooseCompany-label">
          <Text translation="decision.chooseCompany.label" />
        </FormLabel>
        <RadioGroup
          onChange={e => chooseOption(e.target.value)}
          aria-labelledby="chooseCompany-label"
        >
          {radioButtonOptions.map(choice => (
            <FormControlLabel key={choice} value={`Option ${choice}`} control={<Radio />} label={t.formatMessage({ id: `decision.chooseCompany.option${choice}` })} />
          ))}
        </RadioGroup>
      </FormControl>

      {/* TODO: include descriptive error saying this is REQUIRED */}
      <FormLabel id="justification-label">
        <Text translation="decision.justify.label" />
      </FormLabel>
      <TextField
        error={formErrors.justification}
        fullWidth
        required
        id="justification"
        label=""
        multiline
        rows={4}
        margin="dense"
        value={justification}
        onChange={e => setJustification(e.target.value)}
      />
    </Box>
  )
}
Decision.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default Decision
