import React from 'react'

// JS Dependencies
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// internal JS helpers
import treatments from '../../constants/treatments'
import { clockDisplay } from '../../helpers/time'

// internal React Components
import Text from '../Text'

const TreatmentStages = () => {
  const treatmentKeys = Object.keys(treatments)

  const [value, setValue] = React.useState(treatmentKeys[0])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Text variant="h4">Treatment Stages</Text>
      <TabContext value={value}>

        <TabList onChange={handleChange}>
          {treatmentKeys.map(treatment => (
            <Tab key={treatment} label={treatment} value={treatment} />
          ))}
        </TabList>

        {treatmentKeys.map(treatment => (
          <TabPanel key={treatment} value={treatment}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Route</TableCell>
                    <TableCell>Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {treatments[treatment].map(stage => (
                    <TableRow key={stage.key}>
                      <TableCell scope="row"><Text>{stage.key}</Text></TableCell>
                      <TableCell scope="row"><Text>{stage.route}</Text></TableCell>
                      <TableCell scope="row"><Text>{stage.durationInSeconds ? clockDisplay(stage.durationInSeconds) : ''}</Text></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        ))}
      </TabContext>

    </>
  )
}
export default TreatmentStages
