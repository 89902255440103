/* eslint react/jsx-filename-extension: "off", react/no-danger: "off" */

import React, { useState } from 'react'
import { parse } from 'marked'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

const generateRandomString = () => Math.random().toString(36).slice(2)

export const asHtml = markdownText => (
  <div
    key={`markdownAsHtml-${generateRandomString()}`}
    dangerouslySetInnerHTML={{ __html: parse(markdownText) }}
  />
)

const makeCheckbox = ({ label, id, onChange, checked }) => (
  <FormControlLabel
    key={id}
    label={label}
    control={<Checkbox {...{ id, onChange, checked, value: label }} />}
  />
)

export const asCheckboxes = (markdownText, setSelectedData) => {
  const [checked, setChecked] = useState({})

  const onChange = ({ target }) => {
    if (target.checked) {
      checked[target.id] = target.value
    } else {
      delete checked[target.id]
    }
    setChecked({ ...checked })
    setSelectedData(Object.values(checked))
  }

  return (
    <FormGroup>
      {
        markdownText.map((newline, i) => (
          newline.startsWith('[]')
            ? makeCheckbox({
              label: newline.replace('[]', ''),
              id: `fact-${i}`,
              onChange,
              checked: !!checked[`fact-${i}`],
            })
            : asHtml(newline)
        ))
      }
    </FormGroup>
  )
}
