import React from 'react'

// JS dependencies
import LeaderboardIcon from '@mui/icons-material/Leaderboard'

// internal React Components
import Centered from '../components/Centered'
import Text from '../components/Text'

// TODO: retrieve client
const Leaderboard = () => (
  <Centered>
    <LeaderboardIcon fontSize="large" color="primary" />
    <Text>
      hello
    </Text>
  </Centered>
)
export default Leaderboard
