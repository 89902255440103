import React from 'react'
import PropTypes from 'prop-types'

// internal helpers
import * as parsers from '../helpers/markdown/parser'
import * as presenters from '../helpers/markdown/presenter'

// internal React Components
import Text from './Text'

const Checklist = ({ content, setData }) => {
  const parsedContent = parsers.parseStimulusIntoList(content)
  const stimulus = presenters.asCheckboxes(parsedContent, setData)

  return (
    <Text sx={{ px: 3 }}>
      {stimulus}
    </Text>
  )
}
Checklist.propTypes = {
  content: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
}
export default Checklist
