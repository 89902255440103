/* eslint import/prefer-default-export: "off" */

const SEPARATOR = ':'

export const clockDisplay = timeInSeconds => {
  let display = ''

  const dateObj = new Date(timeInSeconds * 1000)
  const hours = dateObj.getUTCHours()
  const minutes = dateObj.getUTCMinutes()
  const seconds = dateObj.getSeconds()

  if (hours > 0) {
    display = hours.toString() + SEPARATOR
      + minutes.toString().padStart(2, '0') + SEPARATOR
  } else {
    display = minutes.toString() + SEPARATOR
  }
  display += seconds.toString().padStart(2, '0')

  return display
}
