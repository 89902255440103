import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// JS dependencies
import {
  Box,
  FormLabel,
  TextField,
} from '@mui/material'
import { RateReviewRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal React Components
import Centered from '../../components/Centered'
import Text from '../../components/Text'

const Feelings = ({ beforeContinue, failContinue, setFormInputs }) => {
  const t = useIntl()

  const [formErrors, setFormErrors] = useState({})
  const [wereYouListenedTo, setWereYouListenedTo] = useState('')

  const validateForm = async () => {
    if (!wereYouListenedTo) {
      setFormErrors({
        wereYouListenedTo: !wereYouListenedTo,
      })
      failContinue()
    }
  }

  useEffect(() => {
    beforeContinue(() => async () => {
      await validateForm()

      setFormInputs({ wereYouListenedTo })
    })
  }, [wereYouListenedTo])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 15, py: 3 }}>
      <Centered>
        <RateReviewRounded fontSize="large" color="primary" style={{ fontSize: '10em' }} />
      </Centered>

      <FormLabel id="wereYouListenedTo-label">
        <Text translation="feelings.form.wereYouListenedTo.label" />
      </FormLabel>
      <TextField
        error={formErrors.wereYouListenedTo}
        fullWidth
        id="wereYouListenedTo"
        label={t.formatMessage({ id: 'feelings.form.wereYouListenedTo.placeholder' })}
        multiline
        rows={4}
        margin="dense"
        value={wereYouListenedTo}
        onChange={e => setWereYouListenedTo(e.target.value)}
      />
    </Box>
  )
}
Feelings.propTypes = {
  beforeContinue: PropTypes.func.isRequired,
  failContinue: PropTypes.func.isRequired,
  setFormInputs: PropTypes.func.isRequired,
}
export default Feelings
