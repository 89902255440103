import React, { useEffect, useState } from 'react'

// JS Dependencies
import Box from '@mui/material/Box'

// internal helpers
import { asHtml } from '../helpers/markdown/presenter'
import {
  getTermsAndConditionsContent,
  parseRepoContent,
} from '../requests/stimuli'
import { raiseError } from './layout/ErrorBoundary'

// internal React Components
import Loading from './Loading'
import Text from './Text'

const Consent = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [tsAndCs, setTsAndCs] = useState('')

  useEffect(() => {
    const onSuccess = response => setTsAndCs(parseRepoContent(response.data.content))
    const onError = () => setError('common.errors.default')
    const onFinally = () => setLoading(false)
    getTermsAndConditionsContent(onSuccess, onError, onFinally)
  }, [])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Box
      sx={{
        display: 'block',
        textAlign: 'left',
        overflow: 'scroll',
        m: 7,
        p: 1,
        height: '15em',
        border: '1px dashed grey',
      }}
    >
      <Text>
        {asHtml(tsAndCs)}
      </Text>
    </Box>
  )
}
export default Consent
