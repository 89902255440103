import React from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'

// internal React Components (pages)
import Audit from './pages/Audit'
import Leaderboard from './pages/Leaderboard'
import RoundEntry from './pages/RoundEntry'
import Sorry from './pages/Sorry'

// internal helper methods
import * as session from './helpers/session'
import Stages from './pages/Stages'
import Preview from './components/audit/Preview'

const AppRoutes = () => {
  const { pathname } = useLocation()

  const navigate = useNavigate()
  // remove trailing slash from URLs and redirect to same URL without trailing slash
  if (pathname.endsWith('/')) {
    navigate(pathname.slice(0, -1))
  }

  // localPlayerId is stored in sessionStorage at EmailSignIn (after authentication)
  const loggedIn = !!session.getLocalPlayerId()

  return (
    <Routes>
      <Route exact path="/round/:roundId" element={<RoundEntry />} key="roundEntry" />
      <Route exact path="/client/:clientId/leaderboard" element={<Leaderboard />} key="leaderboard" />
      <Route path="/health" key="health">
        <Route index element={<Audit />} />
        <Route path="preview" element={<Preview />} />
      </Route>

      {loggedIn && <Route path="/round/:roundId/*" element={<Stages />} />}

      <Route path="*" element={<Sorry />} />
    </Routes>
  )
}
export default AppRoutes
