import React, { useEffect, useState } from 'react'

// JS Dependencies
import LinearProgress from '@mui/material/LinearProgress'
import PropTypes from 'prop-types'

// internal JS helpers
import { clockDisplay } from '../helpers/time'

// Internal React Components
import Text from './Text'

const EIGHT_MINUTES = 480
const TWO_MINUTES = 180
const MILLISECOND_INTERVAL = 1000

// TODO: have the timer make sound

// TODO: ensure timer stays synced across browsers, even on refresh
const CountdownTimer = ({ timeInSeconds, alertTimeRunningOutInSeconds }) => {
  const [secondsLeft, setSecondsLeft] = useState(timeInSeconds)
  const [timer, setTimer] = useState(timeInSeconds)

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/setInterval
    const intervalId = setInterval(() => {
      setSecondsLeft(prevSecondsLeft => (prevSecondsLeft - 1))
    }, MILLISECOND_INTERVAL)
    // use interval in state property to track how much time is left
    setTimer(intervalId)
    // cancel asynchronous tasks when component is unmounted
    return () => clearInterval(intervalId)
  }, [])

  // color countdown clock text RED when <= 2min are on the clock (using MUI color theme)
  const textColor = secondsLeft <= alertTimeRunningOutInSeconds ? 'error.main' : 'black'

  // stop interval (seconds counting down) at 0 seconds left
  if (secondsLeft === 0) {
    clearInterval(timer)
  }
  return (
    <>
      <Text variant="h3" sx={{ color: textColor }}>{clockDisplay(secondsLeft)}</Text>
      <LinearProgress variant="determinate" value={((timeInSeconds - secondsLeft) / timeInSeconds) * 100} />
    </>
  )
}
CountdownTimer.propTypes = {
  timeInSeconds: PropTypes.number,
  alertTimeRunningOutInSeconds: PropTypes.number,
}
CountdownTimer.defaultProps = {
  timeInSeconds: EIGHT_MINUTES,
  alertTimeRunningOutInSeconds: TWO_MINUTES,
}
export default CountdownTimer
