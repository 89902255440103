import React, { useEffect, useRef, useState } from 'react'
import { clockDisplay } from '../../helpers/time'
import * as kfApi from '../../requests/kfApi'
import * as session from '../../helpers/session'

const measurable = (stageKey, WrappedComponent, sandbox) => {
  const submitData = data => {
    if (sandbox) {
      console.info(`data posted to API for ${stageKey}: `, data.data) // eslint-disable-line no-console
    } else {
      kfApi.sendData({
        roundPlayerId: session.getRoundPlayerId(),
        data,
      })
    }
  }

  const MeasurableComponent = props => {
    // timestamp when the stage starts (the page loads)
    const [stageStartTimestamp, startStage] = useState(new Date())
    // timestamp when the player presses the continue button
    const [requestContinueTimestamp, setRequestContinueTime] = useState(new Date())

    // manage all form inputs to include in data collection
    const [playerInputs, setFormInputs] = useState()

    // use a reference instead of a state to keep data current for useEffect
    const dataRef = useRef()
    // use this state to trigger useEffect to update data reference
    // state will be updated to TRUE on socket event: all players have pressed continue
    const [updateDataRef, triggerDataRefUpdate] = useState(false)

    // the code inside this effect is only run when all players have pressed continue,
    // and child component sets new state for updateDataRef
    useEffect(() => {
      // TODO: ensure timestamps are recorded correctly
      const stageEndTimestamp = new Date()

      const totalSeconds = (stageEndTimestamp - stageStartTimestamp) / 1000
      const secondsUntilContinueRequest = (requestContinueTimestamp - stageStartTimestamp) / 1000

      // this is the actual data structure being collected for the API
      dataRef.current = {
        [stageKey]: {
          startedAt: stageStartTimestamp.toUTCString(),
          endedAt: stageEndTimestamp.toUTCString(),
          totalStageTime: clockDisplay(totalSeconds),
          requestedContinueAt: requestContinueTimestamp.toUTCString(),
          timeUntilContinueRequest: clockDisplay(secondsUntilContinueRequest),
          playerInputs,
        },
      }

      if (sandbox) console.info(dataRef.current) // eslint-disable-line no-console
      // here is where the useEffect listens to changes in the updateDataRef state
    }, [updateDataRef, playerInputs])

    const onMount = () => {
      startStage(new Date())
    }
    const onUnmount = data => {
      // TODO: make sure window does not go to next page if submitData fails
      submitData({ data })
    }
    // call custom componentWillMount and componentWillUnmount functions with react hooks
    useEffect(() => {
      onMount()
      return () => {
        onUnmount(dataRef.current)
      }
    }, [])

    return (
      <WrappedComponent
        // allow child component [continuable] to trigger useEffect to update data reference
        // through state: updateDataRef by passing a state update function
        // as a prop into child component
        sendStageData={() => triggerDataRefUpdate(true)}
        {...{ setFormInputs, setRequestContinueTime }}
        {...props}
      />
    )
  }
  // add display name for debugging in browser
  MeasurableComponent.displayName = 'MeasurableComponent'
  return MeasurableComponent
}
measurable.defaultProps = {
  sandbox: false,
}
export default measurable
