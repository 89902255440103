import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// JS Dependencies
import { InfoRounded } from '@mui/icons-material'
import { useIntl } from 'react-intl'

// internal helpers
import { asHtml } from '../../helpers/markdown/presenter'
import { getIntroductionInstructionsContent, parseRepoContent } from '../../requests/stimuli'
import { raiseError } from '../../components/layout/ErrorBoundary'
import * as kfApi from '../../requests/kfApi'
import * as userSystem from '../../helpers/userSystem'
import * as session from '../../helpers/session'
import * as socket from '../../helpers/socket'

// internal React Components
import Centered from '../../components/Centered'
import Consent from '../../components/Consent'
import Loading from '../../components/Loading'
import Text from '../../components/Text'
import AlertModal, { WARNING } from '../../components/AlertModal'

const logStartRound = async () => {
  const now = new Date()
  const round = session.getRound()
  if (session.getLocalPlayerId() === round.player_ids[0]) { // Only one API call per round
    kfApi.startRound(round.id, now.toISOString())
  }
}

const logBrowserData = async () => {
  const metadata = {
    browser: userSystem.compileBrowserData(),
    os: userSystem.compileOsData(),
  }
  // regarding the following line: I know, I know
  const data = { data: { metadata } }
  kfApi.sendData({
    roundPlayerId: session.getRoundPlayerId(),
    data,
  })
}

const Introduction = ({ preventContinue, beforeContinue, sandbox }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [browserAlert, showBrowserAlert] = useState(false)
  const [otherPlayersJoined, setOtherPlayersJoined] = useState(false)
  const [instructions, setInstructions] = useState('')

  const t = useIntl()

  useEffect(() => {
    preventContinue(!sandbox)

    const onSuccess = response => setInstructions(parseRepoContent(response.data.content))
    const onError = () => setError('common.errors.default')
    const onFinally = () => setLoading(false)
    getIntroductionInstructionsContent(onSuccess, onError, onFinally)

    // TODO: log what their stimulus assignment # is
    if (!sandbox) {
      socket.initializeParticipation()
      socket.whenOtherPlayersHaveJoined(() => {
        preventContinue(false)
        setOtherPlayersJoined(true)
      })
    }

    logBrowserData()
    if (!userSystem.isChrome) showBrowserAlert(true)

    beforeContinue(() => logStartRound)
  }, [])

  if (error) { raiseError(error) }
  if (loading) return <Loading />

  return (
    <Centered>
      <AlertModal
        open={browserAlert}
        onClose={() => showBrowserAlert(false)}
        titleText={t.formatMessage({ id: 'introduction.useChromeBrowserDialog.title' })}
        buttonType={WARNING}
        buttonText={t.formatMessage({ id: 'introduction.useChromeBrowserDialog.buttonText' })}
      >
        <Text translation="introduction.useChromeBrowserDialog.message" />
        <Text translation="introduction.useChromeBrowserDialog.helpText" variant="body2" />
      </AlertModal>

      <div id="iconContainer">
        <InfoRounded fontSize="large" color="info" style={{ fontSize: '10em' }} />
      </div>
      <Text>
        {asHtml(instructions)}
      </Text>

      <Consent />
      {/* TODO: agree to terms toggle? */}

      {/* These are some initial instructions about how the activity progresses. */}
      {/* They are only shown here but apply to every stage in the activity. */}
      {/* TODO: make this more important */}
      <Text translation="introduction.doNotLeave" sx={{ fontWeight: 'bold', m: 1 }} />
      {
        otherPlayersJoined
          ? <Text variant="body2" translation="introduction.continueInstructions" />
          : <Text variant="body2" translation="introduction.waitingOnPlayers" />
      }

      {/* TODO: ensure button disables again if a previously-joined player leaves the lobby */}
    </Centered>
  )
}
Introduction.defaultProps = {
  sandbox: false,
}
Introduction.propTypes = {
  preventContinue: PropTypes.func.isRequired,
  beforeContinue: PropTypes.func.isRequired,
  sandbox: PropTypes.bool,
}
export default Introduction
