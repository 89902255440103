import React from 'react'

// internal React Components
import Decision from '../../components/Decision'

const PostInterventionDecision = props => (
  <Decision
    stage="postInterventionDecision"
    {...props}
  />
)

export default PostInterventionDecision
