import React from 'react'

// internal React Components
import Stimulus from '../../components/Stimulus'

const FullProfile = () => (
  <Stimulus context="fullProfile" />
)

export default FullProfile
